.instalmentPlanTextViewContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.priceContainer{
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.container{
  height: 200px;
  max-width: 350px;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  flex-direction: row;
}

.leftSide{
  width: 10%;
  padding-right: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top:5px;
  margin-bottom:19px;
}

.lines{
  display: flex;
  border-left:2px dashed #C1CeD3;
  width: 0;
  height:calc((100% - 45px) / 2)
}

.detailsContainer {
  width: 90%;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.heavyFont {
  font-weight: 500;
}

.colorBlack{
  color:black
}

.colorGrey{
  color:#738287
}

.mainText{
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
}

.subText{
  font-family: Nunito;
  font-size: 12px;
  font-weight: 400;
}

.strikeThrough{
  text-decoration: dashed;
}

.columnFlex{
  display: flex;
  flex-direction: column;
}